<template>
  <v-container class="grey lighten-4 page-content">
    <v-app-bar app color="primary" dark tile>
      <v-text-field
        v-model="query.key"
        label="搜索工单编号"
        @keydown.enter="searchCase()"
        prepend-inner-icon="search"
        solo
        flat
        light
        dense
        hide-details
        clearable
      ></v-text-field>

      <v-spacer></v-spacer>

      <v-btn icon @click="drawer = true">
        <v-icon>filter_list</v-icon>
      </v-btn>

      <template v-slot:extension>
        <v-tabs
          grow
          v-model="query.onlylist"
          @change="searchCase()"
        >
          <v-tab>全部工单</v-tab>

          <v-tab>我创建的</v-tab>

          <v-tab>
            <v-badge v-if="remainCount" color="warning" :content="remainCount">
              我的待办
            </v-badge>
            <span v-else>我的代办</span>
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <v-row class="fill-height overflow-y-auto" v-if="caseList.length">
      <v-col cols="12" v-for="item in caseList" :key="item._id">
        <v-card>
          <v-card-title class="font-weight-regular body-2 primary--text">
            {{
              item.group === 1
                ? "能源"
                : item.group === 2
                ? "调峰"
                : item.group === 3
                ? "售电"
                : item.group === 4
                ? "智科"
                : "无"
            }}
            <span class="mx-2">|</span>
            {{ item.case_number }}
            <v-spacer></v-spacer>
            <span class="success--text" v-if="item.status === 1">进行中</span>
            <span class="grey--text" v-if="item.status === 2">已取消</span>
            <span class="grey--text" v-if="item.status === 4">已完成</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" class="pb-0">
                <div
                  style="
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    color: black;
                  "
                >
                  {{ item.agency?.name }}
                </div>
              </v-col>
              <v-col cols="12">
                <v-row>
                  <v-col cols="6">
                    <span class="black--text font-weight-bold">{{ item.current_task.doingName }}</span>
                  </v-col>
                  <v-col cols="6" align="right">
                    ¥ <span class="primary--text">{{ item.settle_price_total | formatMoney }}</span>
                  </v-col>
                </v-row>
              </v-col>
<!--              <v-col cols="12">-->
<!--                <v-progress-linear-->
<!--                  v-model="progressValue"-->
<!--                  color="primary"-->
<!--                ></v-progress-linear>-->
<!--              </v-col>-->
<!--              <v-col cols="4" class="grey&#45;&#45;text caption">-->
<!--                结算单审批-->
<!--              </v-col>-->
<!--              <v-col cols="4" align="center" class="caption">-->
<!--                付款单审批-->
<!--              </v-col>-->
<!--              <v-col cols="4" align="right" class="grey&#45;&#45;text caption">-->
<!--                财务付款-->
<!--              </v-col>-->
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="px-4 grey--text">
            <span class="caption">{{ item.createTime | formatTime }}</span>
            <v-spacer></v-spacer>
            <span class="body-2">{{ item.producedBy?.personal?.name }}</span>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" class="text-center">
        <v-progress-circular
          v-if="query.page * query.limit < caseCount"
          indeterminate
          color="primary"
          width="2"
          v-intersect="{
            handler: infiniteScrolling,
            options: {
              threshold: [1.0],
            },
          }"
        ></v-progress-circular>
        <span v-else class="caption font-weight-light"
          >已展示{{ caseCount }}条工单，没有更多数据了。</span
        >
      </v-col>
    </v-row>
    <v-row v-else>
      <v-img
        src="/static/empty_data.png"
        contain
        max-width="110"
        class="mx-auto mt-12"
      ></v-img>
    </v-row>
    <v-fab-transition>
      <v-btn
        class="transition-swing"
        v-scroll="onScroll"
        color="secondary"
        v-show="fab"
        fab
        dark
        fixed
        bottom
        right
        @click="toTop"
        style="z-index: 6"
      >
        <v-icon>keyboard_arrow_up</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-navigation-drawer v-model="drawer" app temporary right>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="body-1"> 过滤条件 </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon @click="drawer = !drawer"><v-icon>close</v-icon></v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-card-text>
        <h2 class="subtitle-2 mt-2">业务类型</h2>
        <v-chip-group
          v-if="userBusinessType.length"
          v-model="query.group"
          column
          @change="searchCase()"
        >
          <v-chip
            v-for="type in userBusinessType"
            :key="type.code"
            :value="type.code"
            filter
            label
            outlined
            >{{ type.name }}</v-chip
          >
        </v-chip-group>
        <h2 class="subtitle-2 mt-6 mb-2">代理商</h2>
        <v-autocomplete
          v-model="query.agency"
          :loading="agencyLoading"
          :items="agencyList"
          :search-input.sync="searchAgency"
          item-text="name"
          item-value="_id"
          autocomplete="off"
          outlined
          dense
          flat
          solo
          clearable
          hide-details
          label="输入并选择"
          @change="searchCase()"
        ></v-autocomplete>
        <h2 class="subtitle-2 mt-6">工单状态</h2>
        <v-chip-group
          v-if="caseStatus.length"
          v-model="query.status"
          column
          @change="searchCase()"
        >
          <v-chip
            v-for="status in caseStatus"
            :key="status.code"
            :value="status.code"
            filter
            label
            outlined
            >{{ status.name }}</v-chip
          >
        </v-chip-group>
        <h2 class="subtitle-2 mt-6 mb-2">申请人</h2>
        <v-autocomplete
          v-model="query.producedBy"
          :loading="accountLoading"
          :items="accountList"
          :search-input.sync="searchAccount"
          item-text="personal.name"
          item-value="_id"
          autocomplete="off"
          outlined
          dense
          flat
          solo
          clearable
          label="申请人"
          hide-details
          @change="searchCase()"
        ></v-autocomplete>
      </v-card-text>
      <template v-slot:append>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="drawer = !drawer">隐藏</v-btn>
          <v-btn color="secondary" text @click="resetFilter">清空</v-btn>
        </v-card-actions>
      </template>
    </v-navigation-drawer>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
import store from "@/store";
import { mapGetters } from "vuex";
import {
  FETCH_ACCOUNT_LIST,
  FETCH_AGENCY_LIST,
  FETCH_CASE_LIST,
} from "@/store/actions.type";
import { SET_ERROR } from "@/store/mutations.type";
import Util from "@/common/util";
export default {
  data: () => ({
    caseList: [],
    query: {
      key: "",
      group: "",
      producedBy: "",
      agency: "",
      status: "",
      onlylist: 0,
      page: 1,
      limit: 10,
    },
    defaultQuery: {
      key: "",
      group: "",
      producedBy: "",
      agency: "",
      status: "",
      onlylist: 0,
      page: 1,
      limit: 10,
    },
    drawer: false,
    fab: false,
    searchAccount: null,
    searchAgency: null,
    overlay: false,
    progressValue: 50,
  }),
  watch: {
    searchAccount(val) {
      val && val !== this.query.producedBy && this.searchEmployee(val);
    },
    searchAgency(val) {
      val && val !== this.query.agency && this.getAgencyList(val);
    },
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "userBusinessType",
      "accountLoading",
      "accountList",
      "agencyList",
      "agencyCount",
      "agencyLoading",
      "caseCount",
      "remainCount",
      "caseLoading",
    ]),
    caseStatus() {
      return Util.categories("caseStatus");
    },
  },
  created() {
    this.getList();
  },
  methods: {
    resetFilter() {
      this.query = Object.assign({}, this.defaultQuery);
      this.caseList = [];
      this.getList();
    },
    searchEmployee(key) {
      store.dispatch(FETCH_ACCOUNT_LIST, "&key=" + key);
    },
    getAgencyList(key) {
      store.dispatch(FETCH_AGENCY_LIST, {
        query: "page=1" + "&limit=50" + "&key=" + key,
      });
    },
    searchCase() {
      this.query.page = 1;
      this.query.limit = 10;
      this.caseList = [];
      this.getList();
    },
    getList() {
      this.overlay = true;
      let urlQuery = "";
      if (this.query.key) urlQuery += "&key=" + this.query.key;
      if (this.query.group) urlQuery += "&group=" + this.query.group;
      if (this.query.producedBy)
        urlQuery += "&produced=" + this.query.producedBy;
      if (this.query.agency) urlQuery += "&agency=" + this.query.agency;
      if (this.query.status) urlQuery += "&status=" + this.query.status;
      if (this.query.onlylist) {
        if (this.query.onlylist === 1) {
          urlQuery += "&onlylist=create";
        } else if (this.query.onlylist === 2) {
          urlQuery += "&onlylist=remain";
        }
      }
      if (this.query.page) urlQuery += "&page=" + this.query.page;
      if (this.query.limit) urlQuery += "&limit=" + this.query.limit;
      store
        .dispatch(FETCH_CASE_LIST, {
          query: urlQuery,
        })
        .then((data) => {
          this.caseList.push(...data.docs);
          this.overlay = false;
        })
        .catch((err) => {
          this.overlay = false;
          store.commit(SET_ERROR, { msg: err.data });
        });
    },
    infiniteScrolling() {
      this.query.page++;
      this.getList();
    },
    onScroll() {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || document.documentElement.offsetTop || 0;
      this.fab = top > 300;
    },
    toTop() {
      if (this.$route.hash) {
        this.$router.push({ hash: "" });
      }
      this.$vuetify.goTo(0);
    },
  },
};
</script>

<style scoped>
.page-content {
  min-height: 100%;
}
</style>
